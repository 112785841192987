<template>
  <a-layout class="privacy-policies">
    <TitleBreadcrumb />
    <div class="content-base container" v-html="privacyPolicies"></div>
  </a-layout>
</template>
<script>
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
import { mapGetters } from "vuex";
export default {
  components: { TitleBreadcrumb },
  name: "PrivacyPolicies",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.privacyPolicies.title),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.privacyPolicies.title,
      },
    ],
  },
  computed: {
    ...mapGetters({
      privacyPolicies: "systemConfig/getPrivacyPolicies",
      loading: "systemConfig/getIsLoading",
    }),
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.privacy-policies {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  .content-base {
    padding-top: 60px;
    padding-bottom: 0;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
  *:not(a) {
    color: color(--white) !important;
  }
  a {
    color: color(link) !important;
    text-decoration: underline;
  }
}
</style>
